import { useState, useEffect } from 'react'
import { Popup, Form } from 'semantic-ui-react'
import cn from 'classnames'
import { useFormik } from 'formik'
import InputField from '../../../../components/common/InputField/InputField'
import { ButtonCustom } from '../../../../components/button/ButtonCustom'
import RadioButton from '../../../../components/common/RadioButton/RadioButton'
import Info_Icon from '../../../../Assets/image/Info_Icon.svg'
import CalendarDateTime from '../../../../components/common/DateTime/CalendarDateTime.jsx'

import { TIME_ZONES } from '../../../../utils/time'
import { useHistory } from 'react-router'
import { toast } from '../../../../components/Toast/Toast'
import { wineNftSchema, emptyWineNftSchema } from '../data/schema'
import { CREATE_NFT_STATUSES } from '../data/consts'
import WineName from './WineName/WineName'
import WineDescription from './WineDescription/WineDescription'

import { vatModes } from '../data/dropMode'

import './EditWineft.scss'

const WineftForm = ({
  isEmptyDrop,
  setIsEmptyDrop,
  nftDetails,
  bottleDetails,
  dropDetails,
  start_drop_event_date,
  finish_drop_event_date,
  setFinish_drop_event_date,
  bottle_release_dates,
  allcountryList,
  onOpen,
  getTotalSelectedCount,
  merchants,
  sendNftToApprove,
  onSubmit, // saveEditNftDetails in original,
  isTranslationMode = false,
  hideApproveBtn = false,
}) => {
  const history = useHistory()
  const [timeZoneOptions] = useState([])
  const [minReleaseDate, setMinReleaseDate] = useState({})
  const [isCalender, setIsCalender] = useState(false)
  const [minDateEnd, setMinDateEnd] = useState({})

  useEffect(() => {
    const getMinDate = () => {
      let addNineDays = new Date()

      addNineDays.setDate(addNineDays.getDate() + 2)

      let minDateEnd = {
        year: addNineDays.getUTCFullYear(),
        day: addNineDays.getUTCDate(),
        month: addNineDays.getUTCMonth() + 1, //months from 1-12
      }
      setMinDateEnd(minDateEnd)
    }
    getMinDate()
  }, [])
  useEffect(() => {
    if (dropDetails.updatedAt) {
      // set min date for start drop event
      let releaseMinDate = new Date(dropDetails?.bottle_release_date)
      let minReleaseMinDate = {
        year: releaseMinDate.getUTCFullYear(),
        day: releaseMinDate.getUTCDate(),
        month: releaseMinDate.getUTCMonth() + 1, //months from 1-12
      }
      setMinReleaseDate(minReleaseMinDate)
    }
  }, [dropDetails])
  const nftSchema = isEmptyDrop ? emptyWineNftSchema : wineNftSchema

  const nftFormik = useFormik({
    enableReinitialize: true,
    validationSchema: nftSchema,
    initialValues: {
      nameOfWine: nftDetails?.bottle_data?.creator_meta?.winery_name,
      cuvee: nftDetails?.bottle_data?.wine_name,
      cru: nftDetails?.bottle_data?.vineyard_name,
      classification: nftDetails?.bottle_data?.classification,
      grape: nftDetails?.bottle_data?.grape,
      appellation: bottleDetails?.appellation_obj?.appellation,
      region: bottleDetails?.region_obj?.region_name,
      vintage: nftDetails?.vintage,
      no_of_bottles: nftDetails?.no_of_bottles,
      no_of_nft: nftDetails?.no_of_nft,
      price: nftDetails?.price,
      max_buy_limit: nftDetails?.max_buy_limit,
      timezone: nftDetails?.timezone,
      royality_payment_profilt: nftDetails?.royality_payment_profilt,
      start_drop_event: start_drop_event_date,
      finish_drop_event: finish_drop_event_date,
      bottle_release_date: bottle_release_dates,
      is_visible_count: nftDetails?.is_visible_count,
      is_exclusive_list: nftDetails?.is_exclusive_list,
      abv: nftDetails?.abv,
      price_ex_vat: nftDetails?.price_ex_vat,
      nft_name: nftDetails?.nft_name,
      nft_subtitle: nftDetails?.nft_subtitle,
      winery_perks: nftDetails?.winery_perks,
      drop_mode: nftDetails?.drop_mode,
      fixed_exchange_rate_eur: nftDetails?.fixed_exchange_rate_eur,
      is_featured: nftDetails?.is_featured,
      isRarible: nftDetails?.isRarible,
      merchant_type: nftDetails?.merchant_type,
      is_empty: nftDetails?.is_empty,
      commission_primary: nftDetails?.commission_primary,
      commission_secondary: nftDetails?.commission_secondary,
      cellar_price: nftDetails?.cellar_price,
      avg_price_comparison: nftDetails?.avg_price_comparison,
      left_nfts: nftDetails?.left_nfts,
      sale_price_ex_vat_eur: nftDetails?.sale_price_ex_vat_eur,
      sale_price_eur: nftDetails?.sale_price_eur,
    },
    onSubmit: () => {
      const formValues = getCollectedFormData()

      if (formValues.shipping_location.length || isEmptyDrop) {
        onSubmit(formValues)
        // saveEditNftDetails(formValues)
      } else {
        toast.error('Please choose at least one country')
      }
    },
  })

  const getCollectedFormData = () => {
    let formValues = { ...nftFormik.values }

    if (
      nftFormik.isValid &&
      nftFormik.values?.start_drop_event &&
      nftFormik.values?.finish_drop_event
    ) {
      let releaseDate =
        nftFormik.values.bottle_release_date.year +
        '/' +
        nftFormik.values.bottle_release_date.month +
        '/' +
        nftFormik.values.bottle_release_date.day

      let start_drop_event =
        nftFormik.values.start_drop_event.year +
        '/' +
        nftFormik.values.start_drop_event.month +
        '/' +
        nftFormik.values.start_drop_event.day +
        ' ' +
        nftFormik.values.start_drop_event.hour +
        ':' +
        nftFormik.values.start_drop_event.minute

      let finish_drop_event =
        nftFormik.values.finish_drop_event.year +
        '/' +
        nftFormik.values.finish_drop_event.month +
        '/' +
        nftFormik.values.finish_drop_event.day +
        ' ' +
        nftFormik.values.finish_drop_event.hour +
        ':' +
        nftFormik.values.finish_drop_event.minute

      let start = new Date(start_drop_event)
      let end = new Date(finish_drop_event)

      formValues.bottle_release_date = releaseDate
      formValues.start_drop_event = start.toISOString()
      formValues.finish_drop_event = end.toISOString()
      formValues.id = nftDetails.id

      formValues.fixed_exchange_rate_eur =
        formValues.fixed_exchange_rate_eur === '' ? 0 : formValues.fixed_exchange_rate_eur

      let country = [...allcountryList]
      let shipping_location = []

      country.forEach((element) => {
        if (element.isChecked) {
          shipping_location.push(element.label)
        }
      })

      delete formValues.nameOfWine
      // shipping_location

      formValues['shipping_location'] = shipping_location
    }

    return formValues
  }
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions()

  const disableField =
    dropDetails?.status !== CREATE_NFT_STATUSES.DRAFT &&
    dropDetails?.status !== CREATE_NFT_STATUSES.EMPTY_DRAFT
  const disableFieldApproved =
    dropDetails?.status !== CREATE_NFT_STATUSES.APPROVED &&
    dropDetails?.status !== CREATE_NFT_STATUSES.EMPTY_APPROVED
  const isEnded =
    dropDetails?.status === CREATE_NFT_STATUSES.ENDED ||
    dropDetails?.status === CREATE_NFT_STATUSES.SOLD_OUT

  const handlePrice = (key, value) => {
    const temp400 = Number(value) + Number(value) * 0.2
    nftFormik.setFieldValue(key, temp400.toFixed(2))
  }

  const setParticularFieldValue = (e, field) => {
    nftFormik.setFieldValue(field, e.target.value)
  }

  const getCountrySelectedLabel = () => {
    const selectedCountriesN = getTotalSelectedCount()

    if (selectedCountriesN === allcountryList?.length) {
      return 'All countries selected'
    }
    if (selectedCountriesN === 1) {
      return `${selectedCountriesN} Country selected`
    }

    return `${selectedCountriesN} Countries selected`
  }

  return (
    <Form onSubmit={nftFormik.handleSubmit}>
      <div className='block NftCreate' id='a'>
        <InputField
          name='Reserv price (USDC)'
          label='Name of wine bottle'
          placeholder=''
          id='nameOfWine'
          type='text'
          value={nftFormik.values.nameOfWine}
          disabled
          formik={nftFormik}
          className={cn({ invisible: isTranslationMode })}
        />
        <WineName
          nftFormik={nftFormik}
          disabled={disableField && disableFieldApproved}
          className={cn({ invisible: isTranslationMode })}
        />
        <WineDescription
          nftFormik={nftFormik}
          // disabled={disableField && disableFieldApproved}
          disabled={isEnded}
          isTranslationMode={isTranslationMode}
        />
        <InputField
          name='vintage'
          label='Vintage'
          placeholder='1999'
          id='vintage'
          type='text'
          value={nftFormik.values.vintage}
          onChange={(event) => {
            nftFormik.setFieldValue('vintage', event.target.value)
          }}
          disabled={isEnded}
          maxLength={100}
          className={cn({ invisible: isTranslationMode })}
        />
        <InputField
          name='Alcohol'
          label='Alc.%'
          placeholder='Alcohol %'
          id='abv'
          type='tel'
          value={nftFormik.values.abv}
          onChange={(event) => {
            nftFormik.setFieldValue('abv', event.target.value)
          }}
          formik={nftFormik}
          disabled={isEnded}
          className={cn({ invisible: isTranslationMode })}
        />
        <InputField
          isRequired
          name='Bottles case'
          label='Number of bottles in a case'
          placeholder='Enter number 1 - 20'
          id='no_of_bottles'
          type='tel'
          value={nftFormik.values.no_of_bottles}
          onChange={(event) => {
            nftFormik.setFieldValue('no_of_bottles', event.target.value)
          }}
          disabled={isEnded}
          formik={nftFormik}
          className={cn({ invisible: isTranslationMode })}
        />
        <div className={cn('sectionInfo', { invisible: isTranslationMode })}>
          <InputField
            type='tel'
            isRequired
            name='WiNeFTs minted'
            label='Number of WiNeFTs to be minted'
            placeholder='Ex. 10'
            id='no_of_nft'
            value={nftFormik.values.no_of_nft}
            onChange={(event) => {
              nftFormik.setFieldValue('no_of_nft', event.target.value)
            }}
            formik={nftFormik}
            disabled={disableField && disableFieldApproved}
          />
          <Popup
            className='trigerEdit'
            trigger={
              <div className='InfoToggle'>
                <img src={Info_Icon} alt='Info_Icon' />
              </div>
            }
            content='This is the total # of digital NFTs that the winery wishes to launch on the platform.
           For example, if Winery chooses to release 200 individual bottles to be sold on the platform,
            the number of NFTs would be 200. Similarly, if the winery chooses to release 20 cases, 6 bottles
             in each case, total # of WineFTs to be minted would be 20'
            on={['hover', 'click']}
            position='right center'
          />
        </div>
        <div className={cn('RadioBtn', { invisible: isTranslationMode })}>
          <label className='drop_label radiolabel'>Is NFT count visible to user?</label>
          <div className='RadioInfo'>
            <RadioButton
              label='Yes'
              className='btnRadio'
              id='nftCount1'
              name='is_visible_count'
              value={1}
              checked={nftFormik.values.is_visible_count === 1}
              onChange={(value) => nftFormik.setFieldValue('is_visible_count', value)}
              disabled={isEnded}
            />
            <RadioButton
              label='No'
              className='btnRadio'
              id='is_visible_count2'
              name='is_visible_count'
              value={0}
              checked={nftFormik.values.is_visible_count === 0}
              onChange={(value) => nftFormik.setFieldValue('is_visible_count', value)}
              disabled={isEnded}
            />
            <Popup
              className='trigerEdit'
              trigger={
                <div className='InfoToggle'>
                  <img src={Info_Icon} alt='Info_Icon' />
                </div>
              }
              content='This will always default to “No”. Admin should confirm with WineChain leadership,
             if for any reason Winery wants to provide visibility on volume of available NFTs, to end customer'
              on={['hover', 'click']}
              position='right center'
            />
          </div>
        </div>
        <div className={cn('RadioBtn', { invisible: isTranslationMode })}>
          <label className='drop_label radiolabel'>
            Will the Drop event be specifically meant ONLY for Winery's exclusive access list?
          </label>
          <div className='RadioInfo'>
            <RadioButton
              label='Yes'
              className='btnRadio'
              id='is_exclusive_list1'
              name='is_exclusive_list'
              value={1}
              checked={parseInt(nftFormik.values.is_exclusive_list) === 1}
              onChange={(value) => nftFormik.setFieldValue('is_exclusive_list', value)}
              disabled={isEnded}
            />
            <RadioButton
              label='No'
              className='btnRadio'
              id='is_exclusive_list2'
              name='is_exclusive_list'
              value={0}
              checked={parseInt(nftFormik.values.is_exclusive_list) === 0}
              onChange={(value) => nftFormik.setFieldValue('is_exclusive_list', value)}
              disabled={isEnded}
            />
            <Popup
              className='trigerEdit'
              trigger={
                <div className='InfoToggle'>
                  <img src={Info_Icon} alt='Info_Icon' />
                </div>
              }
              content='selecting “Yes” would mean only very specific selected members who have exclusive access 
            to the Winery can participate in the Drop event. Selecting “No” would imply any member in the platform 
            can participate in the Drop event'
              on={['hover', 'click']}
              position='right center'
            />
          </div>
        </div>
        <div className={cn('RadioBtn', { invisible: isTranslationMode })}>
          <p>
            <label className='drop_label radiolabel'>
              <strong>Select Drop mode with two option - VAT ex and VAT incl</strong>
            </label>
          </p>
          <div className='RadioInfo RadioInfoVat'>
            {vatModes.map((mode) => (
              <RadioButton
                key={mode.value}
                label={mode.label}
                className='btnRadio'
                id={mode.id}
                name='drop_mode'
                value={mode.value}
                checked={nftFormik.values.drop_mode === mode.value}
                onChange={(value) => nftFormik.setFieldValue('drop_mode', value)}
                disabled={isEnded}
              />
            ))}
          </div>
        </div>
        <InputField
          name='Reserv price (USDC)'
          label='Price ex VAT (USDC)'
          placeholder='Ex. 300'
          isRequired
          id='price_ex_vat'
          type='tel'
          min='0'
          value={nftFormik.values.price_ex_vat}
          onChange={(event) => {
            if (event.target.value.length < 10) {
              if (event.target.value === '0' || event.target.value === '') {
                nftFormik.setFieldValue('price_ex_vat', '')
                handlePrice('price', '0')
              } else {
                nftFormik.setFieldValue('price_ex_vat', event.target.value)
                handlePrice('price', event.target.value)
              }
            }
          }}
          formik={nftFormik}
          disabled={isEnded}
          className={cn({ invisible: isTranslationMode })}
        />
        <InputField
          name='Fixed Exchange Rate (EUR)'
          label='Fixed Exchange Rate (EUR)'
          placeholder='Ex. 0.855'
          id='fixed_exchange_rate_eur'
          type='tel'
          min='0'
          value={nftFormik.values.fixed_exchange_rate_eur}
          onChange={(event) => {
            if (event.target.value.length < 10) {
              nftFormik.setFieldValue('fixed_exchange_rate_eur', event.target.value)
            }
          }}
          disabled={isEnded}
          className={cn({ invisible: isTranslationMode })}
        />
        <InputField
          name='Reserv price (EUR)'
          label='Price ex VAT (EUR)'
          placeholder='Ex. 300'
          isRequired
          id='sale_price_ex_vat_eur'
          type='number'
          min='0'
          value={nftFormik.values.sale_price_ex_vat_eur}
          onChange={(event) => {
            if (event.target.value.length < 10) {
              if (event.target.value === '0' || event.target.value === '') {
                nftFormik.setFieldValue('sale_price_ex_vat_eur', '')
                handlePrice('sale_price_eur', '0')
              } else {
                nftFormik.setFieldValue('sale_price_ex_vat_eur', event.target.value)
                handlePrice('sale_price_eur', event.target.value)
              }
            }
          }}
          formik={nftFormik}
          disabled={isEnded}
          className={cn({ invisible: isTranslationMode })}
        />
        <InputField
          name='Insert avg market price comparison'
          label='Insert avg market price comparison'
          placeholder='Ex. $20 or 20%'
          id='avg_price_comparison'
          value={nftFormik.values.avg_price_comparison}
          onChange={(event) => {
            if (event.target.value.length < 10) {
              nftFormik.setFieldValue('avg_price_comparison', event.target.value)
            }
          }}
          disabled={isEnded}
          className={cn({ invisible: isTranslationMode })}
        />
        {nftFormik.values.drop_mode === 'VAT incl' && (
          <div className={cn('createFinalPrice', { invisible: isTranslationMode })}>
            <p className='createFinalPrice__title'>Final Price for the customer including VAT</p>
            <p className='createFinalPrice__text'>
              {nftFormik.values.price ? nftFormik.values.price : '0'} USDC
            </p>
          </div>
        )}
        <div className={cn('InputPresent', { invisible: isTranslationMode })}>
          <InputField
            type='tel'
            min='0'
            className='InfoInput'
            label='Winechain primary fee 0-100%'
            placeholder='Ex. 20%'
            id='commission_primary'
            value={nftFormik.values.commission_primary}
            onChange={(event) => {
              if (
                (event.target.value >= 0 && event.target.value <= 100) ||
                event.target.value === ''
              ) {
                nftFormik.setFieldValue('commission_primary', event.target.value)
              }
            }}
            formik={nftFormik}
            disabled={isEnded}
          />
          <label className='Sublabel'>%</label>
        </div>
        <div className={cn('InputPresent', { invisible: isTranslationMode })}>
          <InputField
            type='tel'
            min='0'
            className='InfoInput'
            label='Winechain secondary fee 0-100%'
            placeholder='Ex. 20%'
            id='commission_secondary'
            value={nftFormik.values.commission_secondary}
            onChange={(event) => {
              if (
                (event.target.value >= 0 && event.target.value <= 100) ||
                event.target.value === ''
              ) {
                nftFormik.setFieldValue('commission_secondary', event.target.value)
              }
            }}
            formik={nftFormik}
            disabled={isEnded}
          />
          <label className='Sublabel'>%</label>
        </div>
        <div className={cn('sectionInfo', { invisible: isTranslationMode })}>
          <InputField
            isRequired
            type={'tel'}
            name='NFTs one Wallet'
            label='Max. number of NFTs one Wallet can buy ?'
            placeholder='Ex. 1'
            id='max_buy_limit'
            value={nftFormik.values.max_buy_limit}
            onChange={(event) => {
              nftFormik.setFieldValue('max_buy_limit', event.target.value)
            }}
            formik={nftFormik}
            disabled={isEnded}
          />
          <Popup
            className='trigerEdit'
            trigger={
              <div className='InfoToggle'>
                <img src={Info_Icon} alt='Info_Icon' />
              </div>
            }
            content='Winery can choose to provide a restriction on the number of NFTs that a particular 
          user/member can be allowed to purchase. Please enter a number between 1 -100'
            on={['hover', 'click']}
            position='right center'
          />
        </div>
        <div
          className={cn('cus_drop inputField__wrap perameter_Inputs', {
            invisible: isTranslationMode,
          })}
        >
          <label className='drop_label'>Time Zone</label>
          <select
            id='timezone'
            value={nftFormik.values.timezone}
            onChange={(e) => setParticularFieldValue(e, 'timezone')}
            disabled={isEnded}
          >
            <option value='' disabled>
              {timeZone}
            </option>
            {TIME_ZONES.map((item) => (
              <option key={item.name} value={item.offset} label={`${item.name} - ${item.offset}`}>
                {timeZoneOptions}
              </option>
            ))}
          </select>
        </div>
        <div className={cn('sectionInfo', { invisible: isTranslationMode })}>
          <div className='perameter_Inputs'>
            <CalendarDateTime
              isRequired
              id='bottle_release_date'
              label='Bottle release date (date the physical wine bottle will be available)'
              value={nftFormik.values.bottle_release_date}
              placeholder='Ex. 13 May 2022'
              disabled={isEnded}
              initValue={bottle_release_dates}
              onChangeHandler={(date) => {
                if (date !== null) {
                  nftFormik.setFieldValue('bottle_release_date', date)
                } else {
                  nftFormik.setFieldValue('bottle_release_date', '')
                }
              }}
              withTime={false}
              minDate={minReleaseDate}
              isDisabled={isEnded}
            />
            {nftFormik.touched.bottle_release_date && (
              <p
                style={{
                  color: 'red',
                  fontSize: '13px',
                  marginRight: 'auto',
                  marginLeft: '0',
                }}
              >
                {nftFormik.errors.bottle_release_date}
              </p>
            )}
          </div>
          <Popup
            className='trigerEdit'
            trigger={
              <div className='InfoToggle'>
                <img src={Info_Icon} alt='Info_Icon' />
              </div>
            }
            content='please change this to “Date the physical wine bottle will be produced and 
          available to be sold on the platform, i.e., bottle release date” Add Tooltip: Release 
          date can be in the future. A Winery can choose to create NFT of a bottle that is going 
          to be released in the future, as long as they can guarantee that such a bottle will be 
          released in the market'
            on={['hover', 'click']}
            position='right center'
          />
        </div>
        <div className={cn('sectionInfo', { invisible: isTranslationMode })}>
          <div className='cus_drop perameter_Inputs'>
            <label className='drop_label'>
              Winery specific geographical restrictions, for shipping only
            </label>

            <button
              disabled={isEnded}
              type='button'
              className='DropCalender'
              onClick={() => {
                if (dropDetails.type !== 'CURRENT') {
                  onOpen()
                }
              }}
            >
              {getCountrySelectedLabel()}
            </button>
          </div>
          <Popup
            className='trigerEdit'
            trigger={
              <div className='InfoToggle'>
                <img src={Info_Icon} alt='Info_Icon' />
              </div>
            }
            content="Winery may have its own restrictions of not shipping to certain parts of the world. 
          These are on top of the global shipping restrictions that a winery might be legally obligated 
          to follow. Note that, restrictions only apply to Shipping. A customer can be located in the 
          specific geographical restricted area and still purchase the WineFT, he just won't be able to 
          ship it to himself"
            on={['hover', 'click']}
            position='right center'
          />
        </div>
        <div className={cn('sectionInfo', { invisible: isTranslationMode })}>
          <div className='InputPresent'>
            <InputField
              type='tel'
              className='InfoInput'
              label='Royalty payments for Secondary sale, on profits from sale'
              placeholder='Ex. 20% as like default'
              id='royality_payment_profilt'
              value={nftFormik.values.royality_payment_profilt?.toString()}
              onChange={(event) => {
                if (event.target.value >= 0 && event.target.value <= 90) {
                  nftFormik.setFieldValue('royality_payment_profilt', event.target.value)
                }
              }}
              disabled={isEnded}
              formik={nftFormik}
            />
            <p className='Sublabel'>%</p>
          </div>
          <Popup
            className='trigerEdit'
            trigger={
              <div className='InfoToggle'>
                <img src={Info_Icon} alt='Info_Icon' />
              </div>
            }
            content={
              <>
                <div className='Listing'>
                  <p>
                    Royalties are based on the profits due to resale of wine in the secondary
                    market. The WineChain platform takes 10% of your profits from resale and the
                    winery also takes a % of profits. For details on Winery’s share of profits,
                    please see the key bottle details in the Drop events page.
                  </p>
                  <h6>Here’s an example:</h6>
                  <ul className='popupList'>
                    <li>Purchase price of bottle: 100 USDC</li>
                    <li>Resale price for bottle on WineChain Marketplace: 150 USDC</li>
                    <li>Profit = 50 USDC</li>
                    <li>Winery’s share (presuming 20% royalty share): 20% * 50 USDC = 10 USDC</li>
                    <li>WineChain Platform’s share: 10% * 50 USDC = 5 USDC</li>
                    <li>Profit for the end user: 50 - 10 -5 USDC = 35 USDC</li>
                    <li>Final revenue for the end user from secondary sale: 185 USDC”</li>
                  </ul>
                </div>
              </>
            }
            on={['hover', 'click']}
            position='right center'
          />
        </div>
        <div className={cn('sectionInfo', { invisible: isTranslationMode })}>
          <div className='perameter_Inputs'>
            <CalendarDateTime
              label='Date of Start Drop Event'
              isRequired
              placeholder='Ex. 13 May 2022, 12h 00m'
              initValue={nftFormik.values.start_drop_event}
              value={nftFormik.values.start_drop_event}
              onChangeHandler={(date) => {
                nftFormik.setFieldValue('start_drop_event', date)
              }}
              withTime={true}
              onCalenderShow={() => setIsCalender(true)}
              onCalenderHide={() => setIsCalender(false)}
              isDisabled={disableField && disableFieldApproved}
            />
            {nftFormik.touched.start_drop_event && (
              <p
                style={{
                  color: 'red',
                  fontSize: '13px',
                  marginRight: 'auto',
                  marginLeft: '0',
                }}
              >
                {nftFormik.errors.start_drop_event}
              </p>
            )}
          </div>
          <Popup
            className='trigerEdit'
            trigger={
              <div className='InfoToggle'>
                <img src={Info_Icon} alt='Info_Icon' />
              </div>
            }
            content='Winechain should be in full control of the exact date of release. Winery 
          can suggest their preferred week for drop event'
            on={['hover', 'click']}
            position='right center'
          />
        </div>
        <div className={cn('perameter_Inputs', { invisible: isTranslationMode })}>
          <CalendarDateTime
            label='Date and time Finish drop event'
            isRequired
            placeholder='Ex. 13 May 2022, 12h 00m'
            withTime={true}
            value={nftFormik.values.finish_drop_event}
            initValue={nftFormik.values.finish_drop_event}
            onChangeHandler={(date) => {
              if (date) {
                nftFormik.setFieldValue('finish_drop_event', date)
              } else {
                setFinish_drop_event_date('')
                nftFormik.setFieldValue('finish_drop_event', '')
              }
            }}
            minDate={minDateEnd}
            onCalenderShow={() => setIsCalender(true)}
            onCalenderHide={() => setIsCalender(false)}
            isDisabled={disableField && disableFieldApproved}
          />
          {nftFormik.touched.finish_drop_event && (
            <p
              style={{
                color: 'red',
                fontSize: '13px',
                marginRight: 'auto',
                marginLeft: '0',
              }}
            >
              {nftFormik.errors.finish_drop_event}
            </p>
          )}
        </div>
        <div className={cn('perameter_Inputs')}>
          <InputField
            type='textArea'
            name='winery_perks'
            label='Winery Perks'
            placeholder='Winery Perks...'
            id='winery_perks'
            value={nftFormik.values.winery_perks}
            onChange={(event) => {
              nftFormik.setFieldValue('winery_perks', event.target.value)
            }}
            disabled={isEnded}
            formik={nftFormik}
          />
        </div>
        <div className={cn('field checkboxBlock', { invisible: isTranslationMode })}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <input
              className=''
              name='is_featured'
              type='checkbox'
              checked={nftFormik.values.is_featured}
              value={nftFormik.values.is_featured}
              onChange={(e) => {
                nftFormik.setFieldValue('is_featured', e.target.checked ? 1 : 0)
              }}
              disabled={isEnded}
            />
            <label>Featured</label>
          </div>
          <p className='infoData'>Note: If selected then it will display on marketplace page</p>
        </div>
        <div className={cn('field checkboxBlock', { invisible: isTranslationMode })}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <input
              className=''
              name='is_empty'
              type='checkbox'
              checked={nftFormik.values.is_empty}
              value={nftFormik.values.is_empty}
              onChange={(e) => {
                nftFormik.setFieldValue('is_empty', e.target.checked ? 1 : 0)
                setIsEmptyDrop(e.target.checked ? true : false)
              }}
              disabled={disableField && disableFieldApproved}
            />
            <label>Empty</label>
          </div>
          <p className='infoData'>Note: If selected then it will display on marketplace page</p>
        </div>
        <div
          className={cn('cus_drop inputField__wrap perameter_Inputs', {
            invisible: isTranslationMode,
          })}
        >
          <label className='inputField__label'>Merchant type</label>
          <select
            showSearch
            id='merchant_type'
            value={nftFormik.values.merchant_type}
            onChange={(e) => {
              nftFormik.setFieldValue('merchant_type', Number(e.target.value))
              if (Number(e.target.value) === 1) {
                nftFormik.setFieldValue('cellar_price', '')
              }
            }}
            disabled={disableField && disableFieldApproved}
          >
            {merchants?.map((item) => (
              <option key={item.merchant_id} value={item.merchant_id} label={item.merchant_name} />
            ))}
          </select>
        </div>
        {nftFormik.values.merchant_type !== 1 && (
          <InputField
            type='tel'
            min='0'
            className={cn('InfoInput', { invisible: isTranslationMode })}
            label='Cellar Price'
            placeholder='Ex. 300'
            id='cellar_price'
            value={nftFormik.values.cellar_price}
            onChange={(event) => {
              if (event.target.value >= 0 || event.target.value === '') {
                nftFormik.setFieldValue('cellar_price', event.target.value)
              }
            }}
            formik={nftFormik}
            disabled={disableField && disableFieldApproved}
          />
        )}
        <div className={cn('field checkboxBlock', { invisible: isTranslationMode })}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <input
              className=''
              name='isRarible'
              type='checkbox'
              checked={nftFormik.values.isRarible}
              value={nftFormik.values.isRarible}
              onChange={(e) => {
                nftFormik.setFieldValue('isRarible', e.target.checked ? 1 : 0)
              }}
              disabled={disableField && disableFieldApproved}
            />
            <label className='inputField__label'>Rarible</label>
          </div>
          <p className='infoData'>Note: This will enable the drop only for Rarible</p>
        </div>
      </div>
      <div className={cn('BtnIn_Edit', { btnInTranslationMode: isTranslationMode })}>
        <div className='BtnListing'>
          {!isTranslationMode && (
            <ButtonCustom
              type='button'
              onClick={() => history.push('/panel/Dropevents')}
              title='Cancel'
              customClass='borderBtn addNew_Btn Listspace'
            />
          )}
          <ButtonCustom
            type='submit'
            title='Save'
            customClass='saveBtn2'
            disabled={isCalender || isEnded}
          />
        </div>
        {!disableField && !hideApproveBtn && (
          <div className='ApprovedBtn'>
            <ButtonCustom
              type='button'
              title='Save and Approve'
              customClass='SendBtn'
              onClick={() => sendNftToApprove(nftFormik, getCollectedFormData)}
            />
          </div>
        )}
      </div>
    </Form>
  )
}

export default WineftForm
